import React, { useState } from 'react';
import Tooltip from '../../content/tooltip/Tooltip';
import './Sidebar.scss';
import { REACT_APP_NAME_APP } from '../../../hooks/enviroment';
import Elements from './other/Elements';
import SocialLinks from './other/SocialLinks';

export default function Sidebar({ toggleSidebar, sidebarStatus }) {

    let path;
    if (REACT_APP_NAME_APP) { path = REACT_APP_NAME_APP; } else { path = ''; }
    const logo = `${path}/assets/img/logo/logo_black_center.png`;

    // Estado para controlar si el Sidebar está expandido o retraído
    const [isExpanded, setIsExpanded] = useState(sidebarStatus);

    // Función para alternar entre expandido y retraído
    const handleSidebarToggle = () => {
        setIsExpanded(!isExpanded);
        toggleSidebar(!isExpanded);
    };

    return (
        <div className={`sidebar ${isExpanded ? 'expanded' : 'retracted'}`}>

            {/* Logo del sidebar */}
            <div className='container_logo'>
                <img src={logo} alt="Logo" className="logo" />

                <span translate="no" className='ocupation'>Full Stack</span>

                <Tooltip text="Toggle Sidebar" position='right'>
                    <button onClick={handleSidebarToggle} className="toggle-btn">
                        {/* Cambia el ícono según el estado */}
                        {isExpanded ? <i className="fa-solid fa-bars"></i> : <i className="fa-solid fa-bars-sort"></i>}
                    </button>
                </Tooltip>
            </div>

            <hr className='divisor'></hr>

            {/* Contenido del Sidebar */}
            <Elements isExpanded={isExpanded} />

            {/* Contenedor de redes sociales */}
            <SocialLinks isExpanded={isExpanded} />
        </div>
    );
}
