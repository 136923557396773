import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.scss';
import { REACT_APP_NAME_APP } from './hooks/enviroment';

import AppRoutes from './App.routes';
import Navbar from './components/design/navbar/Navbar';
import Sidebar from './components/design/sidebar/Sidebar';

function App() {

  // Estado para almacenar el nombre de la página actual
  const [pageTitle, setPageTitle] = useState('');

  // Estado para controlar la visibilidad del Navbar o sidebar
  const [navbarVisible, setNavbarVisible] = useState(true);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  useEffect(() => {
    // Esto puede usarse para realizar un control adicional del Navbar
  }, [navbarVisible, sidebarVisible]);
  
  // Obtener el ancho de la ventana
  const screen = document.body.clientWidth;

  // Controlar el estado del Sidebar
  let sidebarStatus;
  screen <= 1024 ? sidebarStatus = false : sidebarStatus = true;

  const [isSidebarExpanded, setIsSidebarExpanded] = useState(sidebarStatus);

  // Función para manejar el cambio del estado del Sidebar
  const toggleSidebar = (isExpanded) => {
    setIsSidebarExpanded(isExpanded);
  };

  return (
    <div className="App">
      <Router basename={REACT_APP_NAME_APP}>

        {/* Componente de Sidebar */}
        {sidebarVisible && <Sidebar toggleSidebar={toggleSidebar} sidebarStatus={sidebarStatus} />}

        {/* Componentex de contenido */}
        <div className={`main-content ${isSidebarExpanded ? 'expanded' : 'retracted'} ${!sidebarVisible ? 'no-sidebar' : ''}`}>
          {/* Solo mostrar el Navbar si no estamos en la ruta 404 */}
          {navbarVisible && <Navbar />}

          {/* Mostrar el nombre de la página actual */}
          {pageTitle && <h1 className='title-page'>{pageTitle}</h1>}

          {/* Aquí simplemente usas el archivo de rutas */}
          <AppRoutes
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
            isSidebarExpanded={isSidebarExpanded}
            setPageTitle={setPageTitle}
          />
        </div>

      </Router>
    </div>
  );
}

export default App;
