import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import './Navbar.scss';

export default function Navbar() {

    // Obtener la ubicación actual
    const location = useLocation();

    // Función que comprueba si la ruta está activa
    const isActive = (path) => location.pathname === path;

    return (
        <nav className="navbar">
            <Link to="/" className={isActive('/') ? 'active' : ''}>Home</Link>
            <Link to="/about" className={isActive('/about') ? 'active' : ''}>About</Link>
            <Link to="/resume" className={isActive('/resume') ? 'active' : ''}>Resume</Link>
            <Link to="/portfolio" className={isActive('/portfolio') ? 'active' : ''}>Portfolio</Link>
            <Link to="/contact" className={isActive('/contact') ? 'active' : ''}>Contact</Link>
        </nav>
    )
}
