import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';

// Lazy load de los componentes de las páginas
const Home = lazy(() => import('./pages/home/Home'));
const About = lazy(() => import('./pages/about/About'));
const Contact = lazy(() => import('./pages/contact/Contact'));
const Portfolio = lazy(() => import('./pages/portfolio/Portfolio'));
const Resume = lazy(() => import('./pages/resume/Resume'));
const Err404 = lazy(() => import('./pages/error/404/Err404'));

// Fallback para mostrar mientras se carga el componente
function Loading() {
  return <div>Loading...</div>;
}

export default function AppRoutes({ setNavbarVisible, setSidebarVisible, isSidebarExpanded, setPageTitle }) {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {/* Rutas válidas donde el Navbar está visible */}
        <Route path="/" element={
          <Home
            setPageTitle={setPageTitle}
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
            isSidebarExpanded={isSidebarExpanded}
          />
        } />
        <Route path="/about" element={
          <About
            setPageTitle={setPageTitle}
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
            isSidebarExpanded={isSidebarExpanded}
          />
        } />
        <Route path="/resume" element={
          <Resume
            setPageTitle={setPageTitle}
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
            isSidebarExpanded={isSidebarExpanded}
          />
        } />
        <Route path="/portfolio" element={
          <Portfolio
            setPageTitle={setPageTitle}
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
            isSidebarExpanded={isSidebarExpanded}
          />
        } />
        <Route path="/contact" element={
          <Contact
            setPageTitle={setPageTitle}
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
          />
        } />
        {/* Ruta de error 404 sin mostrar el Navbar */}
        <Route path="*" element={
          <Err404
            setPageTitle={setPageTitle}
            setNavbarVisible={setNavbarVisible}
            setSidebarVisible={setSidebarVisible}
          />
        } />
      </Routes>
    </Suspense>
  );
}
