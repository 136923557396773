import React, { useState } from 'react';
import Tooltip from '../../../content/tooltip/Tooltip';
import './_elements.scss';
import { REACT_APP_NAME_APP } from '../../../../hooks/enviroment';
import AlertText from '../../../content/alert/alert_text/AlertText';

export default function Elements({ isExpanded }) {
    const resumeSpanish = `${REACT_APP_NAME_APP}/assets/docs/Curriculum - Armando Velasquez.pdf`;
    const resumeEnglish = `${REACT_APP_NAME_APP}/assets/docs/Resume - Armando Velasquez.pdf`;

    const [message, setMessage] = useState('');

    // Acción para descargar el archivo en español
    const handleDownloadSpanish = () => {
        window.open(resumeSpanish, '_blank');
        setMessage('');
    };

    // Acción para descargar el archivo en inglés
    const handleDownloadEnglish = () => {
        window.open(resumeEnglish, '_blank');
        setMessage('');
    };

    const handleAlert = () => {
        setMessage('Choose the language of the resume to show');
    };

    // Modificación del componente Element para ejecutar funciones o abrir enlaces
    const Element = ({ icon, label, name, url, isDownload }) => {

        const handleClick = (e) => {
            e.preventDefault();

            if (url) {
                if (typeof url === 'function') {
                    url(); // Si url es una función, la ejecuta
                } else {
                    window.open(url, '_blank'); // Si es una URL, abre el enlace
                }
            }
        };

        return (
            <Tooltip text={label} position='right' status={!isExpanded}>
                <a className={`element ${url ? 'selector' : null}`} onClick={handleClick} download={isDownload ? true : undefined}>
                    <div className='icon'>
                        <i className={`fa-thin ${icon}`}></i>
                    </div>
                    <div className='text'>
                        <span className='label'>{label}</span>
                        <span className='name'>{name}</span>
                    </div>
                </a>
            </Tooltip>
        );
    };

    return (
        <>
            <div className="sidebar-content scroll_element">
                <Element icon={"fa-folder-user"} label={"RESUME"} name={"Download"} url={handleAlert} />
                <Element icon={"fa-envelope"} label={"E-MAIL"} name={"josue27.velasquez9@gmail.com"} url={'mailto:josue27.velasquez9@gmail.com?subject=Contacto&body=Hi%Josue!%20I%20would%20like%20to%20contact%20you.'} />
                <Element icon={"fa-circle-phone"} label={"PHONE"} name={"+593 96 318 8745"} url={'tel:+5939167849'} />
                <Element icon={"fa-language"} label={"LANGUAGE"} name={"Spanish / English B1"} />
                <Element icon={"fa-calendar-days"} label={"BIRTH"} name={"27 September, 2000"} />
                <Element icon={"fa-location-crosshairs"} label={"LOCATION"} name={"Santo Domingo - Ecuador"} />
            </div>

            {/* Mostrar la alerta cuando se establezca el mensaje */}
            {message && (
                <AlertText
                    icon="fa-folder-user"
                    title="Show Resume"
                    message={message}
                    setMessage={setMessage}
                    buttons={{
                        btn1name: 'Spanish',
                        btn1Action: handleDownloadSpanish,
                        btn2name: 'English',
                        btn2Action: handleDownloadEnglish
                    }}
                />
            )}
        </>
    );
}
